// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-v-2-case-study-js": () => import("./../../../src/components/v2/case-study.js" /* webpackChunkName: "component---src-components-v-2-case-study-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-become-crm-partner-js": () => import("./../../../src/pages/become-crm-partner.js" /* webpackChunkName: "component---src-pages-become-crm-partner-js" */),
  "component---src-pages-best-crm-system-singapore-for-small-businesses-js": () => import("./../../../src/pages/best-crm-system-singapore-for-small-businesses.js" /* webpackChunkName: "component---src-pages-best-crm-system-singapore-for-small-businesses-js" */),
  "component---src-pages-best-mobile-crm-system-app-ios-android-js": () => import("./../../../src/pages/best-mobile-crm-system-app-ios-android.js" /* webpackChunkName: "component---src-pages-best-mobile-crm-system-app-ios-android-js" */),
  "component---src-pages-best-sales-crm-software-guatemala-for-small-business-js": () => import("./../../../src/pages/best-sales-crm-software-guatemala-for-small-business.js" /* webpackChunkName: "component---src-pages-best-sales-crm-software-guatemala-for-small-business-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-crm-case-studies-[company]-js": () => import("./../../../src/pages/crm-case-studies/[company].js" /* webpackChunkName: "component---src-pages-crm-case-studies-[company]-js" */),
  "component---src-pages-crm-case-studies-js": () => import("./../../../src/pages/crm-case-studies.js" /* webpackChunkName: "component---src-pages-crm-case-studies-js" */),
  "component---src-pages-crm-data-security-protection-js": () => import("./../../../src/pages/crm-data-security-protection.js" /* webpackChunkName: "component---src-pages-crm-data-security-protection-js" */),
  "component---src-pages-crm-email-inbox-integration-js": () => import("./../../../src/pages/crm-email-inbox-integration.js" /* webpackChunkName: "component---src-pages-crm-email-inbox-integration-js" */),
  "component---src-pages-crm-faq-js": () => import("./../../../src/pages/crm-faq.js" /* webpackChunkName: "component---src-pages-crm-faq-js" */),
  "component---src-pages-crm-integrations-[integration]-js": () => import("./../../../src/pages/crm-integrations/[integration].js" /* webpackChunkName: "component---src-pages-crm-integrations-[integration]-js" */),
  "component---src-pages-crm-integrations-js": () => import("./../../../src/pages/crm-integrations.js" /* webpackChunkName: "component---src-pages-crm-integrations-js" */),
  "component---src-pages-crm-partners-tricove-tech-js": () => import("./../../../src/pages/crm-partners/tricove-tech.js" /* webpackChunkName: "component---src-pages-crm-partners-tricove-tech-js" */),
  "component---src-pages-crm-platfrom-pricing-js": () => import("./../../../src/pages/crm-platfrom-pricing.js" /* webpackChunkName: "component---src-pages-crm-platfrom-pricing-js" */),
  "component---src-pages-crm-software-for-small-business-singapore-js": () => import("./../../../src/pages/crm-software-for-small-business-singapore.js" /* webpackChunkName: "component---src-pages-crm-software-for-small-business-singapore-js" */),
  "component---src-pages-crm-support-js": () => import("./../../../src/pages/crm-support.js" /* webpackChunkName: "component---src-pages-crm-support-js" */),
  "component---src-pages-crm-testimonials-js": () => import("./../../../src/pages/crm-testimonials.js" /* webpackChunkName: "component---src-pages-crm-testimonials-js" */),
  "component---src-pages-crm-with-whatsapp-and-aircall-integration-js": () => import("./../../../src/pages/crm-with-whatsapp-and-aircall-integration.js" /* webpackChunkName: "component---src-pages-crm-with-whatsapp-and-aircall-integration-js" */),
  "component---src-pages-crm-with-whatsapp-integration-js": () => import("./../../../src/pages/crm-with-whatsapp-integration.js" /* webpackChunkName: "component---src-pages-crm-with-whatsapp-integration-js" */),
  "component---src-pages-cto-as-a-service-js": () => import("./../../../src/pages/cto-as-a-service.js" /* webpackChunkName: "component---src-pages-cto-as-a-service-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lead-capture-generation-webform-js": () => import("./../../../src/pages/lead-capture-generation-webform.js" /* webpackChunkName: "component---src-pages-lead-capture-generation-webform-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-psg-grant-js": () => import("./../../../src/pages/psg-grant.js" /* webpackChunkName: "component---src-pages-psg-grant-js" */),
  "component---src-pages-redirect-js": () => import("./../../../src/pages/redirect.js" /* webpackChunkName: "component---src-pages-redirect-js" */),
  "component---src-pages-sales-crm-features-ai-crm-tool-for-small-business-js": () => import("./../../../src/pages/sales-crm-features/ai-crm-tool-for-small-business.js" /* webpackChunkName: "component---src-pages-sales-crm-features-ai-crm-tool-for-small-business-js" */),
  "component---src-pages-sales-crm-features-multichannel-integration-for-sales-marketing-js": () => import("./../../../src/pages/sales-crm-features/multichannel-integration-for-sales-marketing.js" /* webpackChunkName: "component---src-pages-sales-crm-features-multichannel-integration-for-sales-marketing-js" */),
  "component---src-pages-sales-dashboard-js": () => import("./../../../src/pages/sales-dashboard.js" /* webpackChunkName: "component---src-pages-sales-dashboard-js" */),
  "component---src-pages-sales-pipeline-management-tool-js": () => import("./../../../src/pages/sales-pipeline-management-tool.js" /* webpackChunkName: "component---src-pages-sales-pipeline-management-tool-js" */),
  "component---src-pages-singapore-sme-v-1-1-js": () => import("./../../../src/pages/singapore-sme-v1.1.js" /* webpackChunkName: "component---src-pages-singapore-sme-v-1-1-js" */),
  "component---src-pages-singapore-sme-v-2-js": () => import("./../../../src/pages/singapore-sme-v2.js" /* webpackChunkName: "component---src-pages-singapore-sme-v-2-js" */),
  "component---src-pages-singapore-whatsapp-crm-js": () => import("./../../../src/pages/singapore-whatsapp-crm.js" /* webpackChunkName: "component---src-pages-singapore-whatsapp-crm-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-pages-whatsapp-crm-features-free-whatsapp-link-generator-js": () => import("./../../../src/pages/whatsapp-crm-features/free-whatsapp-link-generator.js" /* webpackChunkName: "component---src-pages-whatsapp-crm-features-free-whatsapp-link-generator-js" */),
  "component---src-pages-whatsapp-crm-features-free-whatsapp-qr-code-generator-js": () => import("./../../../src/pages/whatsapp-crm-features/free-whatsapp-qr-code-generator.js" /* webpackChunkName: "component---src-pages-whatsapp-crm-features-free-whatsapp-qr-code-generator-js" */),
  "component---src-pages-whatsapp-crm-js": () => import("./../../../src/pages/whatsapp-crm.js" /* webpackChunkName: "component---src-pages-whatsapp-crm-js" */),
  "component---src-pages-whatsapp-crm-system-dubai-uae-js": () => import("./../../../src/pages/whatsapp-crm-system-dubai-uae.js" /* webpackChunkName: "component---src-pages-whatsapp-crm-system-dubai-uae-js" */),
  "component---src-pages-write-for-us-js": () => import("./../../../src/pages/write-for-us.js" /* webpackChunkName: "component---src-pages-write-for-us-js" */)
}

