import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Link from "../Link";
import { productFeatureList } from "../TopAppBar";
import YouTubeIcon from "../../../images/v2/connect-with-us/youtube.svg";
import telegramLogo from "../../../images/v2/connect-with-us/telegramLogo.svg";
import FacebookIcon from "../../../images/v2/connect-with-us/facebook.svg";
import LinkedInIcon from "../../../images/v2/connect-with-us/linkedin.svg";
import SalesCommunityIcon from "../../../images/v2/connect-with-us/salescommunity.svg";
import MediumIcon from "../../../images/v2/connect-with-us/medium.svg";
import instagramIcon from "../../../images/v2/connect-with-us/Instagram.svg";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import useWidth from "../../../hooks/useWidth";

import IMDA_SME_Medium from "../../../images/v2/psg/IMDA_SME_Medium.webp";

import companyLogoLight from "../../../../src/images/v4/logos/companyLogoLight.svg";
import getExtentionLogo from "../../../../src/images/v4/logos/getExtentionLogo.svg";
import appleButton from "../../../images/v4/homepage/images/appleButton.svg";
import playButton from "../../../images/v4/homepage/images/playButton.svg";
import footerImg1 from "../../../images/v2/aams_logo.webp";
import footerImg2 from "../../../images/v2/ncss_logo.webp";
import footerImg3 from "../../../images/v2/sgTech_logo.webp";
import footerImg4 from "../../../images/v2/ace_logo.webp";

import facebookSocialIcon from "../../../images/v4/footerIcons/facebookSocialIcon.svg";
import instaSocialIcon from "../../../images/v4/footerIcons/instaSocialIcon.svg";
import linkdinSocialIcon from "../../../images/v4/footerIcons/linkdinSocialIcon.svg";
import mediumSocialIcon from "../../../images/v4/footerIcons/mediumSocialIcon.svg";
import salesSocialIcon from "../../../images/v4/footerIcons/salesSocialIcon.svg";
import telegramSocialIcon from "../../../images/v4/footerIcons/telegramSocialIcon.svg";
import youtubeSocialIcon from "../../../images/v4/footerIcons/youtubeSocialIcon.svg";

export const FooterLink = (props) => (
  <>
    <Box
      alignItems="center"
      color="#000 !important"
      component={Link}
      display="flex"
      fontSize="body1.fontSize"
      key={props.title}
      mb={"16px !important"}
      to={props.path}
      {...(props.alt ? { alt: props.alt } : {})}
    >
      {/*{props.icon && <props.icon />}*/}
      {props.icon && (
        <Box
          alignItems="center"
          display="flex"
          justifyContent="center"
          // width="30px"
        >
          <Box
            alt={props.title}
            component={"img"}
            mr={1}
            src={props.icon}
            width={props.width}
          />
        </Box>
      )}
      {props.title}
    </Box>
  </>
);

FooterLink.propTypes = {
  title: PropTypes.string,
  path: PropTypes.string,
  alt: PropTypes.string,
  icon: PropTypes.any,
  width: PropTypes.any,
};

const useStyles = makeStyles((theme) => ({
  SmeStyling: {
    width: "100%",
    marginLeft: "10px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginTop: "10px",
      textAlign: "center",
    },
  },
  smeFooterText: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      textAlign: "center",
    },
  },
  chromeIcon: {
    maxWidth: "250px",
  },

  socialLink: {
    marginRight: theme.spacing(1),
    color: "#516f90",
  },
  countrySymbol: {
    borderRadius: "50%",
  },
  memberOf: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gridColumnGap: "30px",
    paddingLeft: "20px",
    borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
    [theme.breakpoints.down("sm")]: {
      // display: "inline-block",
      borderLeft: "none",
      marginTop: "20px",
      paddingLeft: "0px",
      paddingTop: "20px",
      borderTop: "1px solid rgba(0, 0, 0, 0.12)",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "space-between",
      marginLeft: "0px",
    },
  },
  logoLink: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "10px",
    },
  },
  aamslogoLink: {
    height: "32px",
    width: "109px",
    marginLeft: "30px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "5px",
    },
  },
  copyText: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      display: "inline-block",
    },
  },
  copyTextItem: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "inline-block",
    },
  },
  hrLeftLine: {
    width: "43%",
    height: "0px",
    marginLeft: "0px",
    marginRight: "10px",
    marginTop: "1px",
  },
  memberSupported: {
    clear: "both",
    display: "inline-block",
    overflow: "visible",
    whiteSpace: "nowrap",
    marginTop: "-10px",
  },
  hrRightLine: {
    width: "43%",
    height: "0px",
    marginRight: "0px",
    marginLeft: "10px",
    marginTop: "1px",
  },
  footerlinklist: {
    color: "#000 !impotant",
  },
  footerSmg: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
  },
  footerSmgImg: {
    maxWidth: "300px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
    },
  },
  sgImg: {
    marginTop: "18px",
    [theme.breakpoints.down("xs")]: {
      height: "71px",
      width: "188px",
      marginTop: "-3px",
    },
  },
  sgTechImg: {
    [theme.breakpoints.down("xs")]: {
      height: "29px",
      width: "197px",
      marginLeft: "5px",
      marginTop: "-10px",
    },
  },
  aceImg: {
    marginTop: "24px",
    [theme.breakpoints.down("xs")]: {
      height: "55px",
      width: "83px",
      marginTop: "8px",
    },
  },
  ctoImg: {
    [theme.breakpoints.down("xs")]: {
      height: "32px",
      width: "177px",
      marginTop: "10px",
    },
  },
  ncssImg: {
    height: "55px",
    width: "99px",
    marginTop: "10px",
  },
  rowImages: {
    display: "flex",
    gap: theme.spacing(2),
    marginTop: "-15px",
  },
  googlePlay: {
    width: "130px",
    height: "40px",
    objectFit: "contain",
    marginTop: "40px",
    [theme.breakpoints.down("sm")]: {
      width: "100px",
      height: "30px",
    },
  },
  iOSAppStore: {
    width: "130px",
    height: "40px",
    objectFit: "contain",
    marginTop: "40px",
    [theme.breakpoints.down("sm")]: {
      width: "100px",
      height: "30px",
    },
  },
  qrScanner: {
    width: "90px",
    height: "90px",
    objectFit: "contain",
    [theme.breakpoints.down("sm")]: {
      width: "70px",
      height: "70px",
    },
  },
  footerimgs: {
    marginTop: "-130px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  footerimgsSM: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  logoImg: {
    width: "auto",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  logoLink: {
    width: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "18%",
    },
  },

  footerContainer: {
    background:
      "transparent linear-gradient(91deg, #011D32 0%, #00182B 100%) 0% 0% no-repeat padding-box",
  },
  downloadSectionButtonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "15px",
    width: "100%",
    "& img": {
      width: "100%",
    },
  },
}));

const pepperCloud = [
  {
    title: "About us",
    path: "/about",
  },
  {
    title: "Singapore CRM",
    path: "/best-crm-system-singapore-for-small-businesses",
  },
  {
    title: "Partner with us",
    path: "/become-crm-partner",
  },
  {
    title: "Careers",
    path: "/careers",
  },

  {
    title: "Privacy policy",
    path: "/privacy",
  },
  {
    title: "Case studies",
    path: "/crm-case-studies",
  },
  {
    title: "Testimonials",
    path: "/crm-testimonials",
  },
  {
    title: "Terms of service",
    path: "/terms-of-service",
  },
];

const pricing = [
  {
    title: "Pricing plans",
    path: "/crm-platfrom-pricing",
  },
  {
    title: "Avail grant",
    path: "/psg-grant",
  },
];
const whatsappTools = [
  {
    title: "WhatsApp chat link generator ",
    path: "/whatsapp-crm-features/free-whatsapp-link-generator",
  },
  {
    title: "WhatsApp QR code generator",
    path: "/whatsapp-crm-features/free-whatsapp-qr-code-generator",
  },
  // {
  //   title: "WhatsApp chat button generatorr",
  //   path: "/psg-grant",
  // },
];

const ProductUpdate = [
  {
    title: "Blog",
    path: "https://blog.peppercloud.com/",
  },
  {
    title: "Integrations",
    path: "/crm-integrations",
  },
  {
    title: "Product videos",
    path: "https://www.youtube.com/channel/UCZEdLzIpORF29VuemXUn5NQ",
  },
  {
    title: "Singapore grant",
    path: "https://blog.peppercloud.com/a-guide-to-singapore-government-grants/",
  },
  // {
  //   title: "EDG Grant",
  //   path: "https://blog.peppercloud.com/an-overview-of-enterprise-development-grant-edg/",
  // },
  // {
  //   title: "PSG Grant",
  //   path: "https://blog.peppercloud.com/ultimate-guide-to-productivity-solutions-grant/",
  // },
  {
    title: "CTO-as-a-Service",
    path: "/cto-as-a-service",
  },
  // {
  //   title: "Sales Book Synopsis",
  //   path: "https://blog.peppercloud.com/tag/sales-book-synopsis/",
  // },
  {
    title: "Product updates",
    path: "https://blog.peppercloud.com/tag/product-update/",
  },
  {
    title: "FAQs",
    path: "/crm-faq",
  },
  {
    title: "Brochure",
    path: "/brochure.pdf",
    download: "Brochure.pdf",
  },
];

const social = [
  {
    title: "Sales Community",
    path: "https://www.facebook.com/groups/peppercloudsalescommunity",
    icon: SalesCommunityIcon,
    width: "16px",
    alt: "Sales community",
  },
  {
    title: "Facebook",
    path: "https://www.facebook.com/PepperCloudCRM",
    icon: FacebookIcon,
    width: "8px",
    alt: "Facebook page",
  },
  {
    title: "Instagram",
    path: "https://www.instagram.com/pepper.cloud/",
    icon: instagramIcon,
    width: "12px",
    alt: "Instagram +PC page",
  },
  {
    title: "LinkedIn",
    path: "https://www.linkedin.com/company/peppercloud/",
    icon: LinkedInIcon,
    width: "12px",
    alt: "LinkedIn",
  },
  {
    title: "YouTube",
    path: "https://www.youtube.com/channel/UCZEdLzIpORF29VuemXUn5NQ",
    icon: YouTubeIcon,
    width: "12px",
    alt: "Youtube +PC page",
  },
  {
    title: "Telegram",
    path: "https://t.me/peppercloudbot",
    icon: telegramLogo,
    width: "12px",
    alt: "Telegram +PC page",
  },
  {
    title: "Medium",
    path: "https://medium.com/@peppercloud",
    icon: MediumIcon,
    width: "12px",
    alt: "Medium +PC page",
  },
];
export const FeatureList = [
  {
    title: "WhatsApp CRM",
    description: "Integrate with WhatsApp and close deals right from your CRM.",
    path: "/whatsapp-crm",
  },
  {
    title: "AI CRM",
    description: "Integrate with WhatsApp and close deals right from your CRM.",
    path: "/sales-crm-features/ai-crm-tool-for-small-business",
  },
  {
    title: "Multichannel CRM",
    description: "Converse with customers from a single platform.",
    path: "/sales-crm-features/multichannel-integration-for-sales-marketing",
  },
  {
    title: "Sales pipeline",
    description: "Organise your Leads and Opportunities with sales pipelines.",
    path: "/sales-pipeline-management-tool",
  },
  {
    title: "Mobile CRM",
    description:
      "Access your CRM data anywhere with the Pepper Cloud CRM Mobile App. Get mobile CRM system for both iOS & Android.",
    path: "/best-mobile-crm-system-app-ios-android",
  },
  // {
  //   title: "Security",
  //   description: "Safeguard your customers’ data with multi-layered security.",
  //   path: "/crm-data-security-protection",
  // },
  {
    title: "Dashboard",
    description:
      "Track performance and sales visibility through visual dashboards.",
    path: "/sales-dashboard",
  },
  // {
  //   title: "Integrations",
  //   description:
  //     "Communicate efficiently with email, calendar, & other integrations.",
  //   path: "/crm-integrations",
  // },
  {
    title: "Web forms",
    description: "Capture visitors into leads with customisable web forms.",
    path: "/lead-capture-generation-webform",
  },
  // {
  //   title: "Inbox",
  //   // img: inboxImg,
  //   description: "Access your emails and contacts from your CRM inbox.",
  //   path: "/crm-email-inbox-integration",
  // },
  // {
  //   title: "Zoom",
  //   // img: inboxImg,
  //   // description: "Access your emails and contacts from your CRM inbox.",
  //   path: "/zoom",
  // },
  // {
  //   title: "Aircall",
  //   // img: inboxImg,
  //   description: "Aircall",
  //   path: "/aircall-crm-integration",
  // },
  // {
  //   title: "Xero",
  //   // img: inboxImg,
  //   description: "Xero",
  //   path: "/xero",
  // },
];

const Footer = ({ showWidget = true }) => {
  const width = useWidth();
  const classes = useStyles();

  // const addresses = [
  // 	{
  // 		headquarter: true,
  // 		country: "SINGAPORE",
  // 		flag: singaporeImg,
  // 		address: <React.Fragment>#34-04, Tower 1, One Raffles Place, Singapore 048616.</React.Fragment>,
  // 	},
  // 	{
  // 		country: "INDIA",
  // 		flag: indiaImg,
  // 		address: (
  // 			<React.Fragment>
  // 				2nd Floor, Salarpuria Tower 1, 7th Block Koramangala, Bengaluru, India - 560095.
  // 			</React.Fragment>
  // 		),
  // 	},
  // 	{
  // 		country: "MALAYSIA",
  // 		flag: malaysiaImg,
  // 		address: (
  // 			<React.Fragment>
  // 				6th Floor, No, 3, Jalan SS 7/19, 47301 Petaling Jaya, Selangor, Malaysia - 47301.
  // 			</React.Fragment>
  // 		),
  // 	},
  // 	{
  // 		country: "PHILIPPINES",
  // 		flag: philippinesImg,
  // 		address: (
  // 			<React.Fragment>Centre, No. 47 Kamias Road, Barangay Pinyahan, Quezon City, Philippines - 1102.</React.Fragment>
  // 		),
  // 	},
  // 	{
  // 		country: "MYANMAR",
  // 		flag: myanmarImg,
  // 		address: (
  // 			<React.Fragment>No.22(A), Kabaraye Pagoda Road, Bahan Township, Yangon, Myanmar - 11201.</React.Fragment>
  // 		),
  // 	},
  // ];
  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  return (
    //   <footer>
    //     <Box bgcolor="#F5F8FA" pb={4} pt={8}>
    //       <Container>
    //         <Grid container spacing={4}>
    //           <Grid item md={4} sm={6} xs={12}>
    //             <Box
    //               alt="Pepper Cloud"
    //               component={"img"}
    //               curso={"pointer"}
    //               height={{ sm: 40, xs: 34 }[width] || 40}
    //               mb={4}
    //               onClick={scrollToTop}
    //               src={Logo}
    //             />
    //             <Box fontSize="22px" fontWeight="600" mb={1}>
    //               Sell Better. Sell Smarter.
    //             </Box>
    //             <Box
    //               color="#000"
    //               fontSize="body1.fontSize"
    //               mb={6}
    //               pr={{ sm: 0, xs: 0 }[width] || 8}
    //             >
    //               Pepper Cloud is Singapore's best all-in-one sales CRM software
    //               designed for small and medium businesses (SMEs). The CRM system
    //               offers many powerful integrations with tools such as WhatsApp,
    //               Facebook, Mailchimp, Zoom, Xero, and more. With Pepper Cloud
    //               sales CRM, don’t just sell hard, sell smart!
    //             </Box>
    //             <Box fontSize="18px" fontWeight="600" mb={1}>
    //               Chrome Extension
    //             </Box>
    //             <MuiLink
    //               href="https://chrome.google.com/webstore/detail/pepper-cloud-crm-chrome-e/iccmmindhfioainhgehkhdcmapohogff"
    //               target="_blank"
    //             >
    //               <StaticImage
    //                 className={classes.chromeIcon}
    //                 alt="Chrome Extension - Pepper Cloud Sales CRM"
    //                 src="../../../images/v2/chrome-web-store.png"
    //               />
    //             </MuiLink>

    //             <Box
    //               fontSize="18px"
    //               fontWeight="600"
    //               mb={1}
    //               mt={1}
    //               className={classes.footerimgsSM}
    //             >
    //               Mobile App
    //               <Box className={classes.rowImages}>
    //                 <a
    //                   href="https://play.google.com/store/apps/details?id=com.peppercloud"
    //                   target="_blank"
    //                   rel="noopener noreferrer"
    //                 >
    //                   <StaticImage
    //                     alt="Google Play"
    //                     placeholder="blurred"
    //                     src="../../../images/v2/google-play-image.webp"
    //                     className={classes.googlePlay}
    //                   />
    //                 </a>
    //                 <a
    //                   href="https://apps.apple.com/in/app/pepper-cloud-mobile-crm/id6499458415"
    //                   target="_blank"
    //                   rel="noopener noreferrer"
    //                 >
    //                   <StaticImage
    //                     alt="iOS App Store"
    //                     placeholder="blurred"
    //                     src="../../../images/v2/iOS-App-Store.webp"
    //                     className={classes.iOSAppStore}
    //                   />
    //                 </a>
    //                 <StaticImage
    //                   alt="Image 3"
    //                   placeholder="blurred"
    //                   src="../../../images/v2/Qr-Scanner-footer.png"
    //                   className={classes.qrScanner}
    //                 />
    //               </Box>
    //             </Box>

    //             {/* <Grid container>
    // 							{addresses.map((each) => (
    // 								<Grid item key={each.country} md={6} sm={12} xs={12}>
    // 									<Box alignItems="center" display="flex" fontSize={16} fontWeight="600" mb={1}>
    // @@ -277,166 +301,213 @@ const Footer = () => {
    // 								</Grid>
    // 							))}
    // 						</Grid> */}
    //           </Grid>
    //           <Grid item md={2} sm={6} xs={6}>
    //             <Box
    //               className={classes.footerLinksTitle}
    //               fontSize={18}
    //               fontWeight="600"
    //               mb={1}
    //             >
    //               Pepper Cloud
    //             </Box>
    //             <Box pb={2}>
    //               <Box borderBottom={"solid 3px #FF7A59"} width={"65px"} />
    //               <Box component={Divider} mt={"-2px !important"} />
    //             </Box>
    //             {pepperCloud.map((each) => (
    //               <FooterLink
    //                 {...each}
    //                 // color="#000"
    //                 key={each.title}
    //                 className={classes.footerlinklist}
    //               />
    //             ))}
    //             <Box
    //               className={classes.footerLinksTitle}
    //               fontSize={18}
    //               fontWeight="600"
    //               mb={1}
    //               mt={4}
    //             >
    //               Pricing
    //             </Box>
    //             <Box pb={2}>
    //               <Box borderBottom={"solid 3px #FF7A59"} width={"65px"} />
    //               <Box component={Divider} mt={"-2px !important"} />
    //             </Box>
    //             {pricing.map((each) => (
    //               <FooterLink
    //                 {...each}
    //                 color="#000 !important"
    //                 key={each.title}
    //               />
    //             ))}
    //           </Grid>
    //           <Grid item md={2} sm={6} xs={6}>
    //             <Box
    //               className={classes.footerLinksTitle}
    //               fontSize={18}
    //               fontWeight="600"
    //               mb={1}
    //             >
    //               Features
    //             </Box>
    //             <Box pb={2}>
    //               <Box borderBottom={"solid 3px #FF7A59"} width={"65px"} />
    //               <Box component={Divider} mt={"-2px !important"} />
    //             </Box>
    //             <nav>
    //               {FeatureList.map((each) => (
    //                 <FooterLink
    //                   {...each}
    //                   alt={each.description}
    //                   color="#000"
    //                   key={each.title}
    //                 />
    //               ))}
    //             </nav>
    //           </Grid>
    //           <Grid item md={2} sm={6} xs={6}>
    //             <Box
    //               className={classes.footerLinksTitle}
    //               fontSize={18}
    //               fontWeight="600"
    //               mb={1}
    //             >
    //               Resources
    //             </Box>
    //             <Box pb={2}>
    //               <Box borderBottom={"solid 3px #FF7A59"} width={"65px"} />
    //               <Box component={Divider} mt={"-2px !important"} />
    //             </Box>
    //             {ProductUpdate.map((each) => (
    //               <FooterLink {...each} color="#000" key={each.title} />
    //             ))}
    //             <MuiLink download="Brochure.pdf" href={"/brochure.pdf"}>
    //               <FooterLink color="#000" title="Download Brochure" />
    //             </MuiLink>
    //           </Grid>
    //           <Grid item md={2} sm={12} xs={6}>
    //             <Grid container spacing={4}>
    //               <Grid item md={12} sm={6} xs={12}>
    //                 <Box
    //                   className={classes.footerLinksTitle}
    //                   fontSize={18}
    //                   fontWeight="600"
    //                   mb={1}
    //                 >
    //                   Contact Us
    //                 </Box>
    //                 <Box pb={2}>
    //                   <Box borderBottom={"solid 3px #FF7A59"} width={"65px"} />
    //                   <Box component={Divider} mt={"-2px !important"} />
    //                 </Box>
    //                 <MuiLink
    //                   href="tel:+6597510475"
    //                   onClick={conversionEventOnCall}
    //                 >
    //                   <FooterLink
    //                     className={classes.footerlink}
    //                     color={"#000 !important"}
    //                     title="Call (+65 9751 0475)"
    //                   />
    //                 </MuiLink>
    //                 <FooterLink
    //                   color="#000"
    //                   path={"/contact"}
    //                   title="Sales Enquiry"
    //                 />
    //                 <MuiLink href="/crm-support">
    //                   <FooterLink color="#000" title="Support Ticket" />
    //                 </MuiLink>
    //                 {[
    //                   {
    //                     title: "Partnership",
    //                     path: "/become-crm-partner",
    //                   },
    //                   {
    //                     title: "Write for Us",
    //                     path: "/write-for-us",
    //                   },
    //                 ].map((each) => (
    //                   <FooterLink {...each} color="#000" key={each.title} />
    //                 ))}
    //               </Grid>
    //               <Grid item md={12} sm={6} xs={12}>
    //                 <Box
    //                   className={classes.footerLinksTitle}
    //                   fontSize={18}
    //                   fontWeight="600"
    //                   mb={1}
    //                 >
    //                   Connect with Us
    //                 </Box>
    //                 <Box pb={2}>
    //                   <Box borderBottom={"solid 3px #FF7A59"} width={"65px"} />
    //                   <Box component={Divider} mt={"-2px !important"} />
    //                 </Box>
    //                 {social.map((each) => (
    //                   <FooterLink {...each} color="#000" key={each.title} />
    //                 ))}
    //               </Grid>
    //             </Grid>
    //           </Grid>
    //         </Grid>
    //         <Box color="#000" textAlign="end">
    //           Pepper Cloud - Singapore's Best Sales CRM Software
    //         </Box>

    //         <Box
    //           fontSize="18px"
    //           fontWeight="600"
    //           mb={1}
    //           mt={1}
    //           className={classes.footerimgs}
    //         >
    //           Mobile App
    //           <Box className={classes.rowImages}>
    //             <a
    //               href="https://play.google.com/store/apps/details?id=com.peppercloud"
    //               target="_blank"
    //               rel="noopener noreferrer"
    //             >
    //               <StaticImage
    //                 alt="Google Play"
    //                 placeholder="blurred"
    //                 src="../../../images/v2/google-play-image.webp"
    //                 className={classes.googlePlay}
    //               />
    //             </a>
    //             <a
    //               href="https://apps.apple.com/in/app/pepper-cloud-mobile-crm/id6499458415"
    //               target="_blank"
    //               rel="noopener noreferrer"
    //             >
    //               <StaticImage
    //                 alt="iOS App Store"
    //                 placeholder="blurred"
    //                 src="../../../images/v2/iOS-App-Store.webp"
    //                 className={classes.iOSAppStore}
    //               />
    //             </a>
    //             <StaticImage
    //               alt="Image 3"
    //               placeholder="blurred"
    //               src="../../../images/v2/Qr-Scanner-footer.png"
    //               className={classes.qrScanner}
    //             />
    //           </Box>
    //         </Box>
    //       </Container>
    //     </Box>

    //     <Box style={{ display: "flex" }}>
    //       <hr className={classes.hrLeftLine} />

    //       <Box
    //         className={classes.memberSupported}
    //         fontSize="18px"
    //         fontWeight={"600"}
    //       >
    //         Member of / Supported by
    //       </Box>
    //       <hr className={classes.hrRightLine} />
    //     </Box>
    //     <Box bgcolor="#ffffff" py={2}>
    //       <Container>
    //         <Box alignItems="center" justifyContent="space-between">
    //           <Grid alignItems="center" container>
    //             <Grid item md={6} sm={12} xs={12}>
    //               <Box className={classes.footerSmg}>
    //                 <Box
    //                   onClick={() =>
    //                     window.open(
    //                       "https://services2.imda.gov.sg/CTOaaS/DigitalHealthCheck?utm_source=PA+vendor&utm_medium=vendor+site&utm_campaign=SN222"
    //                     )
    //                   }
    //                   sx={{ cursor: "pointer" }}
    //                   component={"img"}
    //                   src={IMDA_SME_Medium}
    //                   alt={"IMDA Pre-approved Solution Provider"}
    //                   className={classes.footerSmgImg}
    //                   margin={0}
    //                   mb={0}
    //                 />
    //                 <Box className={classes.SmeStyling}>
    //                   <ParagraphTypography
    //                     color="text.secondary"
    //                     fontSize={13}
    //                     sx={{ padding: 0, margin: 0 }}
    //                     textAlign="left"
    //                     className={classes.smeFooterText}
    //                   >
    //                     SMEs are eligible for up to 50% Productivity Solutions
    //                     Grant (PSG) support for the adoption of Pepper Cloud CRM
    //                     solution, a Pre-Approved Solution under the IMDA SMEs Go
    //                     Digital programme.
    //                   </ParagraphTypography>
    //                 </Box>
    //               </Box>
    //             </Grid>
    //             <Grid item md={6} sm={12} xs={12}>
    //               <Box className={classes.memberOf}>
    //                 <Box className={classes.logoLink}>
    //                   <MuiLink
    //                     href="https://aams.org.sg/"
    //                     target="_blank"
    //                     mt={5}
    //                     fontSize="20px"
    //                   >
    //                     <StaticImage
    //                       alt="AAMS+Pepper Cloud CRM"
    //                       placeholder="blurred"
    //                       src="../../../images/v2/aams_logo.webp"
    //                       className={classes.logoImg}
    //                     />
    //                   </MuiLink>
    //                 </Box>
    //                 <Box className={classes.logoLink}>
    //                   <MuiLink
    //                     href="https://www.ncss.gov.sg/"
    //                     target="_blank"
    //                     mt={5}
    //                     fontSize="20px"
    //                   >
    //                     <StaticImage
    //                       alt="NCSS+Pepper Cloud CRM"
    //                       placeholder="blurred"
    //                       src="../../../images/v2/ncss_logo.webp"
    //                       className={classes.logoImg}
    //                     />
    //                   </MuiLink>
    //                 </Box>
    //                 <Box className={classes.logoLink}>
    //                   <MuiLink
    //                     href=" https://www.sgtech.org.sg/"
    //                     target="_blank"
    //                     mt={5}
    //                     fontSize="20px"
    //                   >
    //                     <StaticImage
    //                       alt="SG Tech+Pepper Cloud CRM"
    //                       placeholder="blurred"
    //                       src="../../../images/v2/sgTech_logo.webp"
    //                       className={classes.logoImg}
    //                     />
    //                   </MuiLink>
    //                 </Box>
    //                 <Box className={classes.logoLink}>
    //                   <MuiLink
    //                     href=" https://ace.org.sg/"
    //                     target="_blank"
    //                     mt={5}
    //                     fontSize="20px"
    //                   >
    //                     <StaticImage
    //                       alt="ACE+Pepper Cloud CRM"
    //                       placeholder="blurred"
    //                       src="../../../images/v2/ace_logo.webp"
    //                       className={classes.logoImg}
    //                     />
    //                   </MuiLink>
    //                 </Box>
    //               </Box>
    //             </Grid>
    //           </Grid>
    //         </Box>
    //       </Container>
    //     </Box>
    //     <Box bgcolor="#f5f8fa" py={2}>
    //       <Container>
    //         <Box alignItems="center" justifyContent="space-between">
    //           <Grid alignItems="center" container>
    //             <Grid item md={12} sm={12} xs={12}>
    //               <Box className={classes.copyText}>
    //                 <Box className={classes.copyTextItem}>
    //                   <Box fontSize="18px" fontWeight="600" color="#000">
    //                     {`© Pepper Cloud ${new Date().getFullYear()} | `}
    //                   </Box>
    //                   <Box fontSize="16px" mb={0} ml={1} mt="1px">
    //                     Best CRM Software Singapore
    //                   </Box>
    //                 </Box>
    //                 <MuiLink href="/sitemap" mt={5}>
    //                   <Box fontSize="16px" color="#000">
    //                     Site map
    //                   </Box>
    //                 </MuiLink>
    //               </Box>
    //             </Grid>
    //           </Grid>
    //         </Box>
    //       </Container>
    //     </Box>
    //     {showWidget && (
    //       <script
    //         dangerouslySetInnerHTML={{
    //           __html: `
    //     var url = 'https://backend.peppercloud.com/js/widget.js';
    //     var s = document.createElement('script');
    //     s.type = 'text/javascript';
    //     s.async = true;
    //     s.src = url;
    //     var options = {
    //       "enabled": true,
    //       "chatButtonSetting": {
    //         "backgroundColor": "#FF7A59",
    //         "ctaText": "",
    //         "borderRadius": "50",
    //         "marginLeft": "0",
    //         "marginBottom": "50",
    //         "marginRight": "50",
    //         "position": "right",
    //         "btnIcon": "pc-widget-message-icon-white",
    //         "type": "ONLY_BUTTON"
    //       },
    //       "brandSetting": {
    //         "brandName": "Pepper Cloud CRM",
    //         "brandSubTitle": "All-in-one sales CRM",
    //         "brandImg": "https://prod-crmb2b.s3.ap-southeast-1.amazonaws.com/widget/5d70b9708f2c5d071d712b34/b8183faa-3fec-4725-9b6c-d096c8799b3d.png",
    //         "welcomeText": "Greetings! How may we assist you today?",
    //         "messageText": "What would you like to do?",
    //         "backgroundColor": "#2E3F4F",
    //         "ctaText": "Send Message",
    //         "borderRadius": "10",
    //         "autoShow": true,
    //         "phoneNumber": "+6584063651",
    //         "question": "What would you like to do?",
    //         "options": ["Book a Demo", "Get a Call"]
    //       }
    //     };
    //     s.onload = function() {
    //       CreateWhatsappChatWidget(options);
    //     };
    //     var x = document.getElementsByTagName('script')[0];
    //     x.parentNode.insertBefore(s, x);
    //   `,
    //         }}
    //       ></script>
    //     )}
    //   </footer>
    <main>
      <section className={classes.footerContainer}>
        <div className="container">
          <div className="footerSectionOuter">
            <div className="footerSectionContainer">
              <div className="footerLeftSection">
                <div className="footerLeftSectionHeader">
                  <img
                    src={companyLogoLight}
                    alt="Pepper Cloud - Best Sales CRM Software"
                  />
                  <p className="font-wix-regular font-14 color-black5">
                    Best AI-powered WhatsApp sales CRM software for growing
                    businesses
                  </p>
                </div>
              </div>
              <div className="footerRightSection">
                <div className="linkListContainer">
                  <p className="font-wix-semibold font-14 color-black7">
                    Useful links
                  </p>
                  <div className="links">
                    {pepperCloud.map((link) => (
                      <a
                        className="color-black5 font-wix-medium font-14"
                        href={link.path}
                      >
                        {link.title}
                      </a>
                    ))}
                  </div>
                </div>
                <div className="linkListContainer">
                  <p className="font-wix-semibold font-14 color-black7">
                    Features
                  </p>
                  <div className="links">
                    {FeatureList.map((link) => (
                      <a
                        className="color-black5 font-wix-medium font-14"
                        href={link.path}
                      >
                        {link.title}
                      </a>
                    ))}
                  </div>
                </div>
                <div className="linkListContainer">
                  <p className="font-wix-semibold font-14 color-black7">
                    Free WhatsApp tools
                  </p>
                  <div className="links">
                    {whatsappTools.map((link) => (
                      <div>
                        <a
                          className="color-black5 font-wix-medium font-14"
                          href={link.path}
                        >
                          {link.title}
                        </a>
                        <span className="newTag font-12 font-wix-regular font-white">
                          New
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="linkListContainer">
                  <p className="font-wix-semibold font-14 color-black7">
                    Resources
                  </p>
                  <div className="links">
                    {ProductUpdate.map((link) => (
                      <a
                        className="color-black5 font-wix-medium font-14"
                        href={link.path}
                        download={link.download}
                      >
                        {link.title}
                      </a>
                    ))}
                  </div>
                </div>
                <div className="linkListContainer">
                  <div className="linkListInnerContainer">
                    <p className="font-wix-semibold font-14 color-black7">
                      Pricing
                    </p>
                    <div className="links">
                      {pricing.map((link) => (
                        <a
                          className="color-black5 font-wix-medium font-14"
                          href={link.path}
                        >
                          {link.title}
                        </a>
                      ))}
                    </div>
                  </div>
                  <div className="linkListInnerContainer">
                    <p className="font-wix-semibold font-14 color-black7">
                      Contact us
                    </p>
                    <div className="links">
                      <a
                        className="color-black5 font-wix-medium font-14"
                        href="tel:+6597510475"
                      >
                        Call (+65 9751 0475)
                      </a>
                      <a
                        className="color-black5 font-wix-medium font-14"
                        href="/contact"
                      >
                        {" "}
                        Sales enquiry
                      </a>
                      <a
                        className="color-black5 font-wix-medium font-14"
                        href="/crm-support"
                      >
                        Support ticket
                      </a>
                      <a
                        className="color-black5 font-wix-medium font-14"
                        href="/become-crm-partner"
                      >
                        Partnership
                      </a>
                      <a
                        className="color-black5 font-wix-medium font-14"
                        href="/write-for-us"
                      >
                        Write for us
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footerSectionRow">
            <div className="footerLeftSectionBody">
                  <p className="font-wix-regular font-14 font-white">
                    Download our app
                  </p>
                  <div className={classes.downloadSectionButtonContainer}>
                    <a href="https://apps.apple.com/in/app/pepper-cloud-mobile-crm/id6499458415">
                      <img
                        src={playButton}
                        alt="Pepper Cloud Mobile CRM - Google Play"
                      />
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.peppercloud">
                      <img
                        src={appleButton}
                        alt="Pepper Cloud Mobile CRM - iOS App Store"
                      />
                    </a>
                  </div>
                </div>
              <div className="footerRightSectionBody">
                <div className="footerLeftSectionFooter">
                  <p className="font-wix-regular font-14 font-white">
                    Extension
                  </p>
                  <a
                    target="_blank"
                    href="https://chromewebstore.google.com/detail/pepper-cloud-crm-chrome-e/iccmmindhfioainhgehkhdcmapohogff"
                  >
                    <img
                      src={getExtentionLogo}
                      alt="Chrome Extension - Pepper Cloud Sales CRM"
                    />
                  </a>
                </div>
                <div className="socialLinks">
                  <a
                    target="_blank"
                    href="https://www.facebook.com/PepperCloudCRM"
                  >
                    <img src={facebookSocialIcon} alt="facebook + PC page" />
                  </a>
                  <a
                    target="_blank"
                    href="https://www.instagram.com/pepper.cloud/"
                  >
                    <img src={instaSocialIcon} alt="Instagram + PC page" />
                  </a>
                  <a target="_blank" href="https://t.me/peppercloudbot">
                    <img src={telegramSocialIcon} alt="Telegram + PC page" />
                  </a>
                  <a
                    target="_blank"
                    href="https://www.youtube.com/channel/UCZEdLzIpORF29VuemXUn5NQ"
                  >
                    <img src={youtubeSocialIcon} alt="Youtube + PC page" />
                  </a>
                  <a
                    target="_blank"
                    href="https://www.linkedin.com/company/peppercloud/"
                  >
                    <img src={linkdinSocialIcon} alt="LinkedIn +PC page" />
                  </a>
                  <a target="_blank" href="https://medium.com/@peppercloud">
                    <img src={mediumSocialIcon} alt="Medium + PC page" />
                  </a>
                  <a
                    target="_blank"
                    href="https://www.facebook.com/groups/peppercloudsalescommunity"
                  >
                    <img
                      src={salesSocialIcon}
                      alt="Sales community + PC page"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <script>
          {showWidget && (
            <script
              dangerouslySetInnerHTML={{
                __html: `
        var url = "https://prod-crmb2b.s3.ap-southeast-1.amazonaws.com/widget/5d70b9708f2c5d071d712b34/64378ef1569c5d0019e84ddb.js?v=" + Date.now();
    var s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = url;
    s.onload = function() {
     CreateWhatsappChatWidget();
     };
     var x = document.getElementsByTagName('script')[0];
     x.parentNode.insertBefore(s, x);
      `,
              }}
            ></script>
          )}
        </script>
      </section>
      <section>
        <div className="footerContainer">
          <div className="footerLastSectionHeader">
            <p className="color-black2 font-wix-semibold font-18">
              Member of / Supported by
            </p>
          </div>
          <div className="footerLastSectionContainer">
            <div className="footerLastSectionContainerLeft">
              <img
                src={IMDA_SME_Medium}
                alt={"IMDA Pre-approved Solution Provider"}
                className={classes.footerSmgImg}
              />
              <p
                className="color-black2 font-wix-semibold"
                style={{ fontSize: "11px" }}
              >
                SMEs are eligible for up to 50% Productivity Solutions Grant
                (PSG) support for the adoption of Pepper Cloud CRM solution, a
                Pre-Approved Solution under the IMDA SMEs Go Digital programme.
              </p>
            </div>
            <div className="footerLastSectionContainerRight">
              <a href="https://aams.org.sg/" target="_blank">
                <img
                  alt="AAMS+Pepper Cloud CRM"
                  placeholder="blurred"
                  src={footerImg1}
                  className={classes.logoImg}
                />
              </a>
              <a href="https://www.ncss.gov.sg/" target="_blank">
                <img
                  alt="NCSS+Pepper Cloud CRM"
                  placeholder="blurred"
                  src={footerImg2}
                  className={classes.logoImg}
                />
              </a>
              <a href="https://www.sgtech.org.sg/" target="_blank">
                <img
                  alt="SG Tech+Pepper Cloud CRM"
                  placeholder="blurred"
                  src={footerImg3}
                  className={classes.logoImg}
                />
              </a>
              <a href="https://ace.org.sg/" target="_blank">
                <img
                  alt="ACE+Pepper Cloud CRM"
                  placeholder="blurred"
                  src={footerImg4}
                  className={classes.logoImg}
                />
              </a>
            </div>
          </div>
          <div className="border" />
          <div className="footerLastSectionBanner">
            <p className="font-wix-medium font-12">
              <span className="font-wix-bold">
                © Pepper Cloud {new Date().getFullYear()}
              </span>{" "}
              | Best AI-powered WhatsApp Sales CRM Software Singapore
            </p>
            <a href="/sitemap" className="font-wix-medium font-12">
              Site map
            </a>
          </div>
        </div>
      </section>
    </main>
  );
};

Footer.propTypes = {
  showWidget: PropTypes.boolean,
};
export default Footer;
